// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-self-service-js": () => import("./../../../src/pages/SelfService.js" /* webpackChunkName: "component---src-pages-self-service-js" */),
  "component---src-templates-basic-index-js": () => import("./../../../src/templates/basic/index.js" /* webpackChunkName: "component---src-templates-basic-index-js" */),
  "component---src-templates-company-index-js": () => import("./../../../src/templates/company/index.js" /* webpackChunkName: "component---src-templates-company-index-js" */),
  "component---src-templates-project-index-js": () => import("./../../../src/templates/project/index.js" /* webpackChunkName: "component---src-templates-project-index-js" */),
  "component---src-templates-projects-index-js": () => import("./../../../src/templates/projects/index.js" /* webpackChunkName: "component---src-templates-projects-index-js" */),
  "component---src-templates-services-index-js": () => import("./../../../src/templates/services/index.js" /* webpackChunkName: "component---src-templates-services-index-js" */),
  "component---src-templates-solution-index-js": () => import("./../../../src/templates/solution/index.js" /* webpackChunkName: "component---src-templates-solution-index-js" */)
}

