import React, { useState } from "react"

const defaultState = {
  isMenuOpen: false,
  setMessage: () => "",
  closeModal: () => "",
  openModal: () => "",
  message: "",
}

export const myContext = React.createContext(defaultState)

const Provider = props => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const [message, setMessage] = useState("")

  function closeModal() {
    setMessage("")
    setIsMenuOpen(false)
  }

  function openModal(message = "") {
    setMessage(message)
    setIsMenuOpen(true)
  }

  return (
    <myContext.Provider
      value={{ isMenuOpen, setMessage, closeModal, openModal, message }}
    >
      {props.children}
    </myContext.Provider>
  )
}

export default ({ element }) => <Provider>{element}</Provider>
